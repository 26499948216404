@font-face {
  font-family: "gothammedium";
  src: url("./fonts/GothamMedium.woff2") format("woff2"), url("./fonts/GothamMedium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambold";
  src: url("./fonts/GothamBold.woff2") format("woff2"), url("./fonts/GothamBold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothambook";
  src: url("./fonts/GothamBook.woff2") format("woff2"), url("./fonts/GothamBook.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gothamlight";
  src: url("./fonts/GothamLight.woff2") format("woff2"), url("./fonts/GothamLight.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200&display=swap');
.fontbook{
  font-family: "gothambook" !important;
}
.fontbold{
  font-family: "gothambold" !important;
}
.fontmedium{
  font-family: "gothammedium" !important;
}
.fontlight{
  font-family: "gothamlight" !important;
}
.font14rem{
  font-size: 1.4rem;
}
.fontsummary{
  font-size: 1.4rem;
  padding-right: 5px;
}
.icovert{
}
.cursorpointer{
  cursor: pointer;
}
body {
  font-family: "gothamlight" !important;
  background-color: #262626 !important;
  color: white !important;
}
.secondary-pages a{
  color: #E02020 !important;
}
.secondary-pages a:hover{
  opacity: 0.8;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track-piece  {
  background-color: #262626;
}
::-webkit-scrollbar-thumb:vertical {
  height: 30px;
  background-color: #E02020;
}
.h1{
  line-height: 50px !important;
  font-size: 2.5rem !important;
}
.h2{
  line-height: 40px !important;
  font-size: 2.1rem !important;
}
img.dark {
    -webkit-filter: brightness(100%);
}

img.dark:hover {
    -webkit-filter: brightness(60%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}
a:hover img.dark{
    -webkit-filter: brightness(60%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}
.headerhome{
  /*background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./assets/homeheader.jpg');*/
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.videohome{
  position: absolute;
  height: 95vh;
  background-position: center;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}
.language{
  height: 30px;
  border: 2px solid white;
  background-color: white;
  color: #E02020;
  padding: 5px;
}
.languages a, .languages span {
  display: inline-block;
}

.menubutton{
  cursor: pointer;
}
.menubuttonclose{
  cursor: pointer;
  height: 32px;
  margin-top: 23px;
}
.menu{
  width: 0% !important;
  position: fixed;
  right: 0;
  background-color: #E02020;
  top: 0;
  font-size: 28px;
  overflow: hidden;
  z-index: 1000;
  display: flex!important;
  height: 100%;
  transition: width 0.15s;
}
.menu.active{
  width: 100% !important;
}
.menu hr{
  opacity: 1;
}

.footer-languages hr {
  opacity: 1;
}

.footer-languages a{
  text-decoration: none;
  color: white;
  font-size: 15px;
}

.footer-languages a:hover, .languages span:hover{
  text-decoration: none;
  font-family: "gothambold" !important;
  transition: 0.3s;
  color: white;
}

.menu a{
  text-decoration: none;
  color: white;
}
.menu a:hover, .languages span:hover{
  text-decoration: none;
  font-family: "gothambold" !important;
  transition: 0.3s;
  color: white;
}
.menu .languages{
  font-size: 22px;
}
.menu .languages span{
  cursor: pointer;
}
.logo{
  width: 140px;
}
.footer{
  background-color: #E02020;
  color: white;
}
.playover{
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  font-family: "gothammedium" !important;
}
a:hover .playover{
  display: block;
  transition: 0.3s;
}
.imgcarousel{
  object-fit: cover;
  width: 100%;
  max-height: 400px;
}
.carousel a{
  color: white !important;
  text-decoration: none;
}
.carousel a:hover{
  color: #dedede !important;
  transition: 0.3s;
}
.carousel-control-prev{
  bottom: 0 !important;
  top: inherit !important;
  left: 0 !important;
  text-align: left;
}
.carousel-control-next{
  width: inherit !important;
  display: inherit !important;
  color: #E02020 !important;
  right: 15px !important;
  bottom: 0 !important;
  top: inherit !important;
}
.carousel-control-prev-icon, .carousel-control-next-icon{
  width: 55px !important;
  height: 55px !important;
}
.carousel-indicators{
  display: none !important;
}
.carousel-nums{
  color: #E02020 !important;
}
.carousel-inner {
  border-right: 2px solid #E02020;
  border-left: 2px solid #E02020;
  padding-right: 20px;
  padding-left: 20px;
}
.imgrelated{
  object-fit: cover;
  width: 100%;
  max-height: 330px;
  margin-bottom: 30px;
}
.receiverinput{
  background-color: #E02020;
  border: 2px solid white;
  color: white;
  padding: 5px;
  height: 40px;
  width: 300px;
  border-radius: 0;
}
.receiverbutton{
  background-color: #E02020;
  color: white;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  border-left: 0;
  height: 40px;
  font-family: "gothambold" !important;
}
.receiverbutton:hover{
  background-color: white;
  color: #E02020;
}
::placeholder {
  color: white;
  opacity: 1;
  font-style: italic;
}
.borderleft{
  border-left: 2px solid white;
  padding-left: 50px !important;
}
.borderbottom{
  border-bottom: 2px solid white;
}
.footerlinks{
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-family: "gothammedium" !important;
}
.footerlinks:hover{
  opacity: 0.8;
  color: #FFF;
  transition: 0.3s;
}
.ico:hover{
  opacity: 0.8;
  color: #FFF;
  transition: 0.3s;
}
.legaltext{
  font-size: 12px;
}
.redlinemainmessage{
  border-left: 2px solid #E02020 !important;
}
.redline{
  border-left: 2px solid #262626;
  max-height: 0vh;
}
.redlineactive{
  border-left: 2px solid #E02020 !important;
  max-height: 100vh;
  transition: max-height 1.8s ease-in;
}
.redlineright{
  border-right: 2px solid #262626;
  max-height: 0vh;
}
.redlinerightactive{
  border-right: 2px solid #E02020 !important;
  max-height: 100vh;
  transition: max-height 1.8s ease-in;
}
.redlineright2{
  border-right: 2px solid #262626;
}
.mainImage{
  object-fit: cover;
  width: 100%;
  max-height: 600px;
}
.backbutton{
  margin-right: 10px;
}
.transcriptions{
  border-left: 2px solid #E02020 !important;
  border-right: 2px solid #E02020 !important;
}
.transcriptions a, .alsointeresting a{
  text-decoration: none;
  color: white;
}
.transcriptions a:hover, .alsointeresting a:hover{
  color: #dedede;
  transition: 0.3s;
}
.masinspiracionbutton{
  border: 2px solid white;
  color: white;
  font-family: "gothambold" !important;
  width: 100%;
  text-align: center;
  font-size: 18px;
  background-color: inherit;
  padding-top: 10px;
  padding-bottom: 10px;
}
.masinspiracionbutton:hover{
  border: 2px solid #E02020;
  color: #E02020;
  transition: 0.5s;
}
.divmasinsp a:hover hr{
  display: none;
}
.masinspiracionbutton a{
  color: white;
  text-decoration: none;
}
.divmasinsp a{
  width: 100%
}

hr.hr1 {
  height: 30px !important;
  color: #262626!important;
  position: absolute;
  bottom: 0px;
  left: 20px;
  width: 20px;
  opacity: 1;
  margin: inherit;
}
hr.hr2 {
  height: 30px !important;
  color: #262626!important;
  position: absolute;
  bottom: 0px;
  left: 60px;
  width: 20px;
  opacity: 1;
  margin: inherit;
}
/*About us*/
.verticalline{
  height: 140px;
}
.verticalline .line{
  width: 2px;
  background-color: #E02020;
  height: 0%;
  left: 50%;
  margin: auto;
}
.verticalline .line.active{
  transition: height 1.2s ease-in;
  height: 100%;
}
.verticalline2{
  height: 400px;
}
.verticalline2 .line{
  width: 2px;
  background-color: #E02020;
  height: 0%;
}
.verticalline2 .line.active{
  transition: height 1.2s ease-in;
  height: 100%;
}
p{
  font-size: 18px;
  line-height: 20px;
}

/*Videos*/
.red{
  color: #E02020;
}
.taglist{
  font-size: 14px;
}
.taglist div:hover{
  color: #FFF;
  transition: 0.3s;
  font-weight: bold;
}
.elementos a{
  text-decoration: none;
  color: white;
  position: relative;
}
.elementos a:hover{
  color: #dedede;
  transition: 0.3s;
}
.quote{
  max-width: 300px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
}
.quoteico{
  margin-right:20px;
  width: 80px;
}
.quotetxt{
  font-style: italic;
}
.volver{
  color: white;
  text-decoration: none;
}
.volver:hover{
  color: #dedede;
  transition: 0.3s;
}
.iframenewsletter{
  width: 100%;
}

@media (min-width: 992px){
  .h1{
    line-height: 70px !important;
    font-size: 3.5rem !important;
  }
  .h2{
    line-height: 50px !important;
    font-size: 2.5rem !important;
  }
  .menu.active{
    width: 50% !important;
  }
  .menu.inactive{
    width: 0% !important;
  }
  .destacado1{
    padding-right: 40px;
  }
  .destacado2{
    padding-left: 40px;
  }
  .redlineright{
    border-right: 0 !important;
  }
  .redlineright2{
    border-right: 0 !important;
  }
  .carousel-control-prev{
    right: 40px;
    top: 0 !important;
    left: inherit !important;
    width: inherit !important;
    display: inherit !important;
    color: #E02020 !important;
  }
  .carousel-control-next{
    top: 0 !important;
    width: inherit !important;
    display: inherit !important;
    color: #E02020 !important;
    right: 15px !important;
  }
  .carousel-control-prev-icon, .carousel-control-next-icon{
    width: 35px !important;
    height: 35px !important;
  }
  .masinspiracionbutton{
    width: 400px;
    font-size: 13px;
  }
  .divmasinsp a{
    width: auto;
  }
  .alsointeresting{
    border-left: 2px solid #E02020 !important;
    border-right: 2px solid #E02020 !important;
  }
  .iframenewsletter{
    width: 70%;
  }
  .playover{
    display: none;
  }
}

/* typewriter effect */
.Typewriter{
  font-family: "gothambold" !important;
  display: inline-block;
}
.Typewriter__cursor{
  color: #E02020;
  font-family: "gothamlight" !important;
}
.Typewriter__wrapper{
  display: inline-block;
}

/* Animation home main message */
@keyframes fadeInUp {
  from {
    transform: translate3d(0,40px,0)
  }
  to {
    transform: translate3d(0,0,0);
    opacity: 1
  }
}
@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0,40px,0)
  }
  to {
    transform: translate3d(0,0,0);
    opacity: 1
  }
}
.animated {
  animation-duration: 1.5s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-fill-mode: both
}
.animatedFadeInUp {
  opacity: 0
}
.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

/* mac-os hacks */
.redlinemainmessage.mac-os {
  padding-top: 20px;
}
.masinspiracionbutton.mac-os{
  padding-top: 15px;
}
.receiverbutton.mac-os{
  padding-top: 7px;
}

.pagination > .page-item > .page-link {
  background-color: transparent;
  color: white;
  border: none;
  box-shadow: none;
}

.pagination > .page-item.disabled > .page-link {
  background-color: transparent;
  opacity: 0;
}

.pagination > .page-item.active > .page-link {
  background-color: #E02020;
}
.displayIB{
  display: inline-block;
}

/*scroll button*/
.top-to-btm{
  position: relative;
}
.icon-position{
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}
.icon-style{
  background-color: #551B54;
  border: 2px solid #fff;
  height: 42px;
  width: 42px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all .5s ease-in-out;
}
.icon-style:hover{
  animation: none;
  background: #fff;
  color: #E02020;
  border: 2px solid #E02020;
}
@keyframes movebtn {
  0%{
    transform: translateY(0px);
  }
  25%{
    transform: translateY(10px);
  }
  50%{
    transform: translateY(0px);
  }
  75%{
    transform: translateY(-2px);
  }
  100%{
    transform: translateY(0px);
  }
}

.introduction-title {
  width: 80vw;
  margin: auto;
}

@media (min-width: 992px){
  .introduction-title {
    width: 40vw;
  }
}

@keyframes slide-up {
	from {
		bottom: -100%; /* Posición inicial */
	}
	to {
		bottom: 0; /* Posición final */
	}
}

.banner-container {
	bottom: 0;
	position: fixed;
	z-index: 99;
}

.banner-body {
	background-color: rgba(25, 31, 34, 0.92);
	border-radius: var(--internal-cookie-consent-banner-border-radius-body);
	box-shadow: rgba(57, 57, 57, 0.38) 0px -3px 13px 0px;
	font-size: 11px;
}

.banner-button-secondary {
	box-sizing: border-box;
	min-width: 0px;
	appearance: none;
	display: inline-block;
	margin: 0px 0.25rem;
	padding: 0.5rem 1rem;
	background-color: var(--internal-cookie-consent-banner-colors-primary);
	border-radius: var(--internal-cookie-consent-banner-border-radius-buttons);
	border-width: 1px;
	border-color: var(--internal-cookie-consent-banner-colors-primary-border);
	border-style: solid;
	color: var(--internal-cookie-consent-banner-colors-primary-content);
	font-size: 12px;
	font-weight: 700;
	line-height: inherit;
	text-decoration: none;
	text-align: center;
  font-family: "gothambold" !important;
}

.banner-button-primary {
	box-sizing: border-box;
	min-width: 0px;
	appearance: none;
	display: inline-block;
	margin: 0px 0.25rem;
	padding: 0.5rem 1rem;
	background-color: #ffffff;
	border-radius: var(--internal-cookie-consent-banner-border-radius-buttons);
	border-width: 1px;
	border-color: #ffffff;
	border-style: solid;
	color: #e02020;
	font-size: 12px;
	font-weight: 700;
	line-height: inherit;
	text-decoration: none;
	text-align: center;
	font-family: "gothambold" !important;
}

@media (min-width: 992px){
  .banner-button-secondary,
  .banner-button-primary {
    width: fit-content !important;
  }
}

@media (max-width: 992px){
  .banner-container{
    left: 0 !important;  
  }
}

